import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import AboutBasicTextModule from "../components/AboutBasicTextModule/AboutBasicTextModule"

const blog = () => {
  return (
    <>
      <Seo title="About us" />
      <Layout>
        <SimpleBanner title="About us">
          <StaticImage
            className="banner__image"
            src="../images/clark-van-der-beken-mcCAFvlzZFQ-unsplash.jpg"
            alt=""
          />
        </SimpleBanner>
        <AboutBasicTextModule />
      </Layout>
    </>
  )
}

export default blog
