import * as React from "react"
//import Button from "../Button/Button"
import { AboutBasicTextModuleStyles } from "./AboutBasicTextModuleStyles"
//import { Link } from "gatsby"

const AboutBasicTextModule = () => {
  return (
    <AboutBasicTextModuleStyles>
      <div className="container">
        <h2>
        Thinking Cap is a Brisbane-based group with deep experience building web solutions using Open Source software such as Drupal. 
        </h2>
        <p style={{ marginBottom: " 0px" }}>
        Established in Brisbane in 2005, we provide consulting on concept, design, development, maintenance and extension of web properties. </p>
<p>We&#39;ve worked in industry sectors including higher education, government (council, state), health, not-for-profit, professional services, at various levels from development teams, engineering teams, to management.</p>
<p>Our solutions combine Drupal at the backend with decoupled front-ends such as React or Vue.js. </p>
<p>We  contribute a perspective to your projects informed by a broad and deep experience across platforms such as Drupal, Salesforce, Stripe, Xero, GitLab, GitHub, BitBucket, Amazon Web Services (AWS) and Microsoft Azure.</p>
<p>We value integrity, excellence,  openness and positivity. Our long-term relationships with our clients is one way we gauge our success in upholding our values over the past 16 years.</p>
<p>Web services  include:</p>
<ul>
<li>Consulting <ul>
<li>Advice and strategy with methodology and project management.</li>
</ul>
</li>
<li>Web development<ul>
<li>We use Open Source tools and frameworks to deliver beautiful solutions that are timely and pragmatic.</li>
<li>We  can slot into your organisation, working as a part of  your technology team or marketing team to up-skill and deliver the project.</li>
</ul>
</li>
<li>Maintenance and support<ul>
<li>Keeping your Open Source software up-to-date.</li>
<li>How do I ...? Supporting your team with timely and helpful tips and information on how make the most of your Open Source software.</li>
</ul>
</li>
<li>Extensions to existing web properties. <ul>
<li>We&#39;ve often been called in to help bolster an existing web property with additional functionality, or to fix things.</li>
</ul>
</li>
<li>Web engineering at all levels of the technology stack<ul>
<li>Our developers  are also engineers with 16 plus years of experience and in-depth understanding at all levels of the web technology stack. </li>
<li>There are  many frameworks and toolsets available at all levels of the stack – we can help navigate this, selecting appropriate technologies to match your team&#39;s strengths while balancing your organisation&#39;s rules and culture.   </li>
</ul>
</li>
</ul>


<p>How do we do this?</p>
<ul>
<li>Our office is Milton,  Brisbane.</li>
<li>Our staff are based in Australia (we don&#39;t off-shore any of our work).</li>
<li>We work in an agile way.</li>
<li>We use tools such GitHub, BitBucket and GitLab for version control, issue tracking, project management, and documentation. All interactions with our clients are recorded here, providing transparency and a useful history once the project has completed.</li>
</ul>


        <p><a href="/contact">Contact us</a> to see how we can help.</p>

        {/* <Button text="Read more about us" as={Link} to="/products" /> */}
      </div>
    </AboutBasicTextModuleStyles>
  )
}

export default AboutBasicTextModule
